import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import FeatureSection from './FeatureSection';
import AITools from './AITools';
import FAQSection from './FAQSection';
import CallToAction from './CallToAction';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Header />
      <HeroSection />
      <FeatureSection />
      <AITools />
      <FAQSection />
      <CallToAction />
    </div>
  );
};

export default LandingPage;