import React from 'react';
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
  const { t } = useTranslation();

  const features = [
    { key: 'textToImage', icon: '📝' },
    { key: 'photoToAnime', icon: '📷' },
    { key: 'animeModels', icon: '🎨' }
  ];

  return (
    <section className="py-20 bg-gray-800 bg-opacity-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('features.title')}</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={feature.key} className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out animate-fadeIn" style={{animationDelay: `${index * 300}ms`}}>
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-xl font-bold mb-2">{t(`features.${feature.key}.title`)}</h3>
              <p className="text-gray-300">{t(`features.${feature.key}.description`)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;