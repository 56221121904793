import React from 'react';
import ReplicateFluxServer from '../components/ReplicateFluxServer';

const GeneratorPage = () => {
  return (
    <div className="generator-page" style={{ minHeight: '100vh', backgroundColor: '#1f2937' }}>
      <ReplicateFluxServer />
    </div>
  );
};

export default GeneratorPage;