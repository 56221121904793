import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState(null);

  return (
    <LanguageContext.Provider value={{ showLanguagePopup, setShowLanguagePopup, detectedLanguage, setDetectedLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};