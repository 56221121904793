// languageUtils.js (或包含语言映射和选择逻辑的文件)

import languages from './languages';

// 更新国家代码到语言代码的映射
export const countryToLanguageMap = {
  // 现有映射
  'US': 'en', 'GB': 'en', 'AU': 'en',
  'CN': 'zh', 'TW': 'zh', 'HK': 'zh',
  'JP': 'ja',
  'KR': 'ko',
  'DE': 'de', 'AT': 'de', 'CH': 'de',
  'FR': 'fr', 'BE': 'fr', 'CA': 'fr',
  'ES': 'es', 'MX': 'es', 'AR': 'es',
  'PT': 'pt', 'BR': 'pt',
  'VN': 'vi',
  'TH': 'th',
  'ID': 'id',
  'SA': 'ar', 'AE': 'ar', 'EG': 'ar',
  // 添加新的映射
  'IT': 'it',
  'RU': 'ru',
  'IN': 'hi',
  'BD': 'bn',
  'MY': 'ms',
  'PH': 'fil',
  'NL': 'nl',
  'PL': 'pl',
  'SE': 'sv',
  'TR': 'tr',
  'UA': 'uk',
  'GR': 'el',
  'IL': 'he',
  'IR': 'fa',
  'PK': 'ur',
  'NP': 'ne',
  // 可以根据需要继续添加更多映射
};

export function getLanguageFromCountry(countryCode) {
  const languageCode = countryToLanguageMap[countryCode.toUpperCase()];
  console.log(`Attempting to get language for country code: ${countryCode}`);
  console.log(`Mapped language code: ${languageCode}`);
  
  const language = languages.find(lang => lang.code === languageCode) || languages.find(lang => lang.code === 'en');
  console.log('Selected language:', language);
  
  return language;
}

export function isValidLanguage(languageCode) {
  const isValid = languages.some(lang => lang.code === languageCode);
  console.log(`Checking validity of language code: ${languageCode}, Is valid: ${isValid}`);
  return isValid;
}

// 新增：获取浏览器语言并匹配到支持的语言
export function getBrowserLanguage() {
  const browserLang = navigator.language || navigator.userLanguage;
  console.log(`Browser language: ${browserLang}`);
  
  const languageCode = browserLang.split('-')[0]; // 获取主要语言代码
  const matchedLanguage = languages.find(lang => lang.code === languageCode) || languages.find(lang => lang.code === 'en');
  
  console.log(`Matched language from browser: ${matchedLanguage.code}`);
  return matchedLanguage;
}

// 新增：从 localStorage 获取保存的语言设置
export function getSavedLanguage() {
  const savedLang = localStorage.getItem('userLanguage');
  console.log(`Saved language from localStorage: ${savedLang}`);
  
  if (savedLang && isValidLanguage(savedLang)) {
    return languages.find(lang => lang.code === savedLang);
  }
  return null;
}

// 新增：保存语言设置到 localStorage
export function saveLanguagePreference(languageCode) {
  localStorage.setItem('userLanguage', languageCode);
  console.log(`Saved language preference: ${languageCode}`);
}