import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createClient } from '@supabase/supabase-js';
import placeholderImage from '../../assets/images/placeholder-image.png';
import image1 from '../../assets/images/pixai1.jpeg';
import image2 from '../../assets/images/zmo-ai.jpeg';
import image3 from '../../assets/images/yodayo1.jpg';
import image4 from '../../assets/images/perchance.jpg';
import image5 from '../../assets/images/getimg-ai.jpg';
import imageFlux from '../../assets/images/flux-ai.png'; // 确保这个图片文件存在

const supabase = createClient(
  'https://gyrhgjvhucsdkyghdqgn.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd5cmhnanZodWNzZGt5Z2hkcWduIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEwNTYwNDIsImV4cCI6MjAzNjYzMjA0Mn0.KtupOkvPo5cvEbrtx6dTC2YUB428HqN8N2LwBpncpn4'
);

const toolsConfig = [
  { url: 'https://pixai.art/', imageUrl: image1 },
  { url: 'https://www.zmo.ai/ai-anime-generator/', imageUrl: image2 },
  { url: 'https://yodayo.com/text-to-image/', imageUrl: image3 },
  { url: 'https://perchance.org/ai-anime-generator', imageUrl: image4 },
  { url: 'https://getimg.ai/use-cases/anime-ai-art-generator', imageUrl: image5 }
];

const AITools = () => {
  const { t, i18n } = useTranslation();
  const [tools, setTools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTools = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const currentLanguage = i18n.language;
    const urls = toolsConfig.map(tool => tool.url);
    try {
      const { data, error } = await supabase
        .from('ai_tools_translations')
        .select(`id, name, url, info_url, title_${currentLanguage}, content_${currentLanguage}`)
        .in('url', urls);

      if (error) throw error;

      const updatedTools = toolsConfig.map(config => {
        const toolData = data.find(tool => tool.url === config.url);
        return {
          ...toolData,
          title: toolData ? toolData[`title_${currentLanguage}`] : '',
          content: toolData ? toolData[`content_${currentLanguage}`] : '',
          imageUrl: config.imageUrl || placeholderImage,
        };
      });

      // 添加新的 Flux AI 工具
      const fluxAiTool = {
        id: 'flux-ai',
        name: 'Flux AI Anime Generator',
        url: 'https://www.aianime-generator.org/generator',
        imageUrl: imageFlux,
        title: t('aiTools.fluxAiTitle'),
        content: t('aiTools.fluxAiDescription'),
        isSpecial: true
      };

      setTools([fluxAiTool, ...updatedTools]);
    } catch (err) {
      console.error('Error fetching tools:', err);
      setError('Failed to load AI tools. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [i18n.language, t]);

  useEffect(() => {
    fetchTools();
  }, [fetchTools]);

  if (isLoading) {
    return <div className="text-center py-20">Loading AI tools...</div>;
  }

  if (error) {
    return <div className="text-center py-20 text-red-500">{error}</div>;
  }

  return (
    <section id="aiTools" className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-white">{t('aiTools.title')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {tools.map((tool, index) => (
            <div 
              key={tool.id || index} 
              className={`rounded-lg overflow-hidden shadow-lg flex flex-col animate-fadeIn hover:shadow-2xl transition-shadow duration-300 ${
                tool.isSpecial 
                  ? 'bg-purple-900 border-2 border-purple-400 animate-pulse-border' 
                  : 'bg-gray-800'
              }`} 
              style={{ animationDelay: `${index * 150}ms` }}
            >
              <img src={tool.imageUrl} alt={tool.name} className="w-full h-48 object-cover" />
              <div className="p-4 flex flex-col flex-grow">
                <h3 className={`text-xl font-semibold mb-2 ${tool.isSpecial ? 'text-purple-200' : 'text-white'}`}>{tool.name}</h3>
                <p className={`text-sm mb-4 flex-grow ${tool.isSpecial ? 'text-purple-100' : 'text-gray-400'}`}>
                  {tool.content}
                </p>
                <div className="mt-auto flex space-x-2">
                  {tool.isSpecial ? (
                    <a 
                      href={tool.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="inline-block bg-purple-600 text-white text-sm px-4 py-2 rounded-md hover:bg-purple-500 transition duration-300 w-full text-center"
                    >
                      {t('aiTools.tryNowForFree')}
                    </a>
                  ) : (
                    <>
                      <a 
                        href={tool.info_url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-block bg-gray-700 text-gray-300 text-sm px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 flex-1 text-center"
                      >
                        {t('aiTools.viewDetail')}
                      </a>
                      <a 
                        href={tool.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-block bg-purple-700 text-white text-sm px-4 py-2 rounded-md hover:bg-purple-600 transition duration-300 flex-1 text-center"
                      >
                        {t('aiTools.tryNow')}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AITools;