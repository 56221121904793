import React from 'react';
import { useTranslation } from 'react-i18next';

const FAQSection = () => {
  const { t } = useTranslation();

  const faqs = ['howToUse', 'commercial', 'skills'];

  return (
    <section className="py-20 bg-gray-800 bg-opacity-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t('faq.title')}</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={faq} className="bg-gray-700 p-6 rounded-lg animate-fadeIn" style={{animationDelay: `${index * 300}ms`}}>
              <h3 className="text-xl font-bold mb-2">{t(`faq.questions.${faq}.question`)}</h3>
              <p className="text-gray-300">{t(`faq.questions.${faq}.answer`)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;