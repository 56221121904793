import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import zhTranslations from './locales/zh.json';
import jaTranslations from './locales/ja.json';
import koTranslations from './locales/ko.json';
import deTranslations from './locales/de.json';
import frTranslations from './locales/fr.json';
import esTranslations from './locales/es.json';
import ptTranslations from './locales/pt.json';
import viTranslations from './locales/vi.json';
import thTranslations from './locales/th.json';
import idTranslations from './locales/id.json';
import arTranslations from './locales/ar.json';
import bnTranslations from './locales/bn.json';
import elTranslations from './locales/el.json';
import faTranslations from './locales/fa.json';
import filTranslations from './locales/fil.json';
import heTranslations from './locales/he.json';
import hiTranslations from './locales/hi.json';
import itTranslations from './locales/it.json';
import msTranslations from './locales/ms.json';
import neTranslations from './locales/ne.json';
import nlTranslations from './locales/nl.json';
import plTranslations from './locales/pl.json';
import ruTranslations from './locales/ru.json';
import svTranslations from './locales/sv.json';
import trTranslations from './locales/tr.json';
import ukTranslations from './locales/uk.json';
import urTranslations from './locales/ur.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations },
      ja: { translation: jaTranslations },
      ko: { translation: koTranslations },
      de: { translation: deTranslations },
      fr: { translation: frTranslations },
      es: { translation: esTranslations },
      pt: { translation: ptTranslations },
      vi: { translation: viTranslations },
      th: { translation: thTranslations },
      id: { translation: idTranslations },
      ar: { translation: arTranslations },
      bn: { translation: bnTranslations },
      el: { translation: elTranslations },
      fa: { translation: faTranslations },
      fil: { translation: filTranslations },
      he: { translation: heTranslations },
      hi: { translation: hiTranslations },
      it: { translation: itTranslations },
      ms: { translation: msTranslations },
      ne: { translation: neTranslations },
      nl: { translation: nlTranslations },
      pl: { translation: plTranslations },
      ru: { translation: ruTranslations },
      sv: { translation: svTranslations },
      tr: { translation: trTranslations },
      uk: { translation: ukTranslations },
      ur: { translation: urTranslations },
    },
    fallbackLng: 'en',
    supportedLngs: [
      'en', 'zh', 'ja', 'ko', 'de', 'fr', 'es', 'pt', 'vi', 'th', 'id', 'ar',
      'bn', 'el', 'fa', 'fil', 'he', 'hi', 'it', 'ms', 'ne', 'nl', 'pl',
      'ru', 'sv', 'tr', 'uk', 'ur'
    ],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      lookupFromPathIndex: 0, // 从路径中检测语言
    },
  });

// 检查路径中的语言代码并自动切换语言
const pathLang = window.location.pathname.split('/')[1];
if (pathLang && i18n.languages.includes(pathLang)) {
  i18n.changeLanguage(pathLang);
}

export default i18n;
