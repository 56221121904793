import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

// Import all images
import landingpage1 from '../../assets/images/landingpage_1.png';
import landingpage2 from '../../assets/images/landingpage_2.png';
import landingpage3 from '../../assets/images/landingpage_3.png';
import landingpage4 from '../../assets/images/landingpage_4.png';
import landingpage5 from '../../assets/images/landingpage_5.png';
import landingpage6 from '../../assets/images/landingpage_6.png';
import landingpage7 from '../../assets/images/landingpage_7.png';
import landingpage8 from '../../assets/images/landingpage_8.png';
import landingpage9 from '../../assets/images/landingpage_9.png';
import landingpage10 from '../../assets/images/landingpage_10.png';

// Import user images
import user1 from '../../assets/images/user_1.jpeg';
import user2 from '../../assets/images/user_2.jpeg';
import user3 from '../../assets/images/user_3.jpeg';
import user4 from '../../assets/images/user_4.jpeg';
import user5 from '../../assets/images/user_5.jpeg';

const ImageGallery = () => {
  const images = [
    landingpage1, landingpage2, landingpage3, landingpage4, landingpage5,
    landingpage6, landingpage7, landingpage8, landingpage9, landingpage10
  ];

  const columns = [
    [images[0], images[3], images[6], images[9]],
    [images[1], images[4], images[7]],
    [images[2], images[5], images[8]]
  ];

  return (
    <div className="grid grid-cols-3 gap-2 overflow-hidden pb-16" style={{ maxHeight: '650px' }}>
      {columns.map((column, colIndex) => (
        <div key={colIndex} className="flex flex-col space-y-2">
          {column.map((img, imgIndex) => (
            <img
              key={`${colIndex}-${imgIndex}`}
              src={img}
              alt={`AI generated anime ${colIndex * 4 + imgIndex + 1}`}
              className="w-full h-auto object-cover rounded-lg"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gradient-to-r from-gray-900 to-purple-900 text-white overflow-hidden">
      <div className="container mx-auto flex flex-col lg:flex-row items-stretch justify-between min-h-screen -mt-16 pt-4">
        <div className="lg:w-3/5 flex flex-col justify-center space-y-10 pr-0 lg:pr-10">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight animate-fadeIn">
            {t('title')}
          </h1>
          <h2 className="text-xl font-semibold text-gray-300 animate-fadeIn animation-delay-300 pr-4 lg:pr-12">
            {t('The All-In-One AI Creative toolkit')}
          </h2>
          <p className="text-lg text-gray-300 animate-fadeIn animation-delay-300 pr-4 md:pr-8 lg:pr-16 whitespace-normal break-words mb-2">
            {t('subtitle')}
          </p>
          <div className="animate-fadeIn animation-delay-600">
            <Link
              to="aiTools"
              smooth={true}
              duration={500}
              className="inline-flex items-center bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
            >
              {t('startButton')}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
          <div className="flex items-center animate-fadeIn animation-delay-900">
            <div className="flex -space-x-1 mr-4">
              {[user2, user3, user4, user5, user1].map((user, i) => (
                <img
                  key={i}
                  className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                  src={user}
                  alt={`User ${i + 1}`}
                />
              ))}
            </div>
            <span className="text-sm">{t('249,140,879+ AI generated images')}</span>
          </div>
        </div>
        <div className="lg:w-2/5 flex items-end animate-fadeIn animation-delay-1200 -mt-32">
          <ImageGallery />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;