import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageProvider, LanguageContext } from './contexts/LanguageContext';
import LandingPage from './components/LandingPage/LandingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Footer from './components/LandingPage/Footer';
import { fetchGeoLocation } from './utils/geoLocation';
import { getLanguageFromCountry } from './utils/languageUtils';
import GeneratorPage from './pages/GeneratorPage.js'; // 更改为 GeneratorPage

function AppContent() {
  const { i18n } = useTranslation();
  const { setShowLanguagePopup, setDetectedLanguage } = React.useContext(LanguageContext);
  const [initialCheck, setInitialCheck] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkLanguage = async () => {
      const storedLanguage = localStorage.getItem('language');
      if (!storedLanguage || storedLanguage !== i18n.language) {
        try {
          const data = await fetchGeoLocation();
          const detectedLang = getLanguageFromCountry(data.country_code);
          if (detectedLang.code !== i18n.language) {
            setDetectedLanguage(detectedLang);
            setShowLanguagePopup(true);
          }
        } catch (error) {
          console.error('Error fetching geolocation:', error);
        }
      }
      setInitialCheck(true);
    };

    checkLanguage();
  }, [i18n.language, setDetectedLanguage, setShowLanguagePopup]);

  if (!initialCheck) {
    return null; // 或者显示一个加载指示器
  }

  const showFooter = !['/privacy-policy', '/terms-of-service', '/generator'].includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-purple-900 text-white">
      <Routes>
        <Route path="/:lang" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/generator" element={<GeneratorPage />} /> {/* 修改为 GeneratorPage */}
        <Route path="/" element={<Navigate to={`/${i18n.language}`} replace />} />
      </Routes>
      {showFooter && <Footer />}
    </div>
  );
}

function App() {
  return (
    <LanguageProvider>
      <Router>
        <AppContent />
      </Router>
    </LanguageProvider>
  );
}

export default App;