import React from 'react';
import { useTranslation } from 'react-i18next';

const CallToAction = () => {
  const { t } = useTranslation();

  return (
    <section className="py-20 bg-purple-700">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold mb-4">{t('cta.title')}</h2>
        <p className="text-xl mb-8">{t('cta.subtitle')}</p>
        <button className="bg-white text-purple-700 font-bold py-3 px-8 rounded-full hover:bg-gray-200 transition-colors duration-300">
          {t('cta.button')}
        </button>
      </div>
    </section>
  );
};

export default CallToAction;