export async function fetchGeoLocation() {
    try {
      const response = await fetch('https://ipapi.co/json/');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return {
        country_code: data.country_code,
        country_name: data.country_name
      };
    } catch (error) {
      console.error('Error fetching geolocation:', error);
      throw error;
    }
  }