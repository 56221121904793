import React from 'react';
import { Link } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher';

const Header = () => {
  return (
    <header className="bg-gray-800 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">AI Anime Generator</div>
        <div className="flex items-center space-x-4">
          <Link 
            to="/generator" 
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Try our AI Anime Generator
          </Link>
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );
};

export default Header;