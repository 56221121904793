import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const RatioButton = ({ ratio, selected, onClick }) => {
  const [width, height] = ratio.split(':').map(Number);
  const aspectRatio = width / height;

  return (
    <div
      onClick={() => onClick(ratio)}
      style={{
        width: '23%',
        height: '70px',
        margin: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: selected ? '#393F4C' : 'transparent',
        border: `1px solid ${selected ? '#495360' : '#4B5563'}`,
        borderRadius: '8px',
        color: '#fff',
        cursor: 'pointer',
        padding: '8px 0',
      }}
    >
      <div
        style={{
          width: aspectRatio > 1 ? '24px' : `${24 * aspectRatio}px`,
          height: aspectRatio < 1 ? '24px' : `${24 / aspectRatio}px`,
          backgroundColor: '#39404B',
          border: '1px solid #495360',
          borderRadius: '4px',
        }}
      />
      <div style={{ fontSize: '14px' }}>{ratio}</div>
    </div>
  );
};

const StylePreset = ({ name, promptAddition, imagePath, onClick }) => {
  return (
    <div
      onClick={() => onClick(promptAddition)}
      style={{
        width: '70px',
        cursor: 'pointer',
        marginRight: '8px',
        marginBottom: '8px',
      }}
    >
      <div style={{
        width: '70px',
        height: '70px',
        backgroundColor: '#292929',
        borderRadius: '8px',
        overflow: 'hidden',
      }}>
        <img src={imagePath} alt={name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <p style={{ color: '#9CA3AF', marginTop: '4px', fontSize: '10px', textAlign: 'center' }}>{name}</p>
    </div>
  );
};

const ImageCard = ({ image, prompt, aspectRatio, onRegenerate, onDownload, timestamp, loading, error }) => {
  const [width, height] = aspectRatio.split(':').map(Number);
  const cardAspectRatio = width / height;

  return (
    <div style={{ marginBottom: '20px', width: '100%', maxWidth: '600px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ color: '#808289', fontSize: '14px' }}>{timestamp} (fast)</div>
        <div style={{ backgroundColor: '#d3a653', color: 'white', padding: '2px 5px', borderRadius: '4px', fontSize: '12px' }}>Flux</div>
      </div>
      <div
        style={{
          width: '100%',
          paddingTop: `${100 / cardAspectRatio}%`,
          backgroundColor: '#292929',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {loading ? (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: '18px',
          }}>
            Generating...
          </div>
        ) : error ? (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ff4444',
            fontSize: '18px',
          }}>
            Error: {error}
          </div>
        ) : (
          <img
            src={image}
            alt="Generated Content"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        )}
      </div>
      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
        <button
          onClick={() => onDownload(image)}
          disabled={loading || !image}
          style={{
            padding: '8px 16px',
            fontSize: '14px',
            color: '#fff',
            backgroundColor: '#373737',
            border: 'none',
            borderRadius: '5px',
            cursor: (loading || !image) ? 'not-allowed' : 'pointer',
          }}
        >
          ⬇️ Download
        </button>
        <button
          onClick={() => onRegenerate(prompt, aspectRatio)}
          disabled={loading}
          style={{
            padding: '8px 16px',
            fontSize: '14px',
            color: '#fff',
            backgroundColor: '#373737',
            border: 'none',
            borderRadius: '5px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          🔄 Edit & Re-generate
        </button>
      </div>
      <div style={{ marginTop: '10px', color: '#9CA3AF', fontSize: '14px' }}>
        Prompt: {prompt}
      </div>
    </div>
  );
};

const ExampleCard = ({ image, prompt, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: '150px',
        marginBottom: '20px',
        cursor: 'pointer',
      }}
    >
      <div style={{
        width: '150px',
        height: '150px',
        backgroundColor: '#292929',
        borderRadius: '8px',
        overflow: 'hidden',
      }}>
        <img
          src={image}
          alt="Example"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </div>
      <p style={{ color: '#9CA3AF', marginTop: '5px', fontSize: '12px' }}>{prompt}</p>
    </div>
  );
};

const AdPopup = ({ onClose }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4270301326382732";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    (window.adsbygoogle = window.adsbygoogle || []).push({});

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative',
        width: '80%',
        maxWidth: '600px',
        height: '80%',
        maxHeight: '600px',
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            color: '#333',
          }}
        >
          ✖
        </button>
        <ins className="adsbygoogle"
          style={{ display: 'block', width: '100%', height: '100%' }}
          data-ad-client="ca-pub-4270301326382732"
          data-ad-slot="2436925917"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      </div>
    </div>
  );
};

const ReplicateFluxServer = () => {
  const [prompt, setPrompt] = useState("");
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [showExamples, setShowExamples] = useState(true);

  const ratios = ['1:1', '16:9', '2:3', '3:2', '4:5', '5:4', '9:16'];

  const stylePresets = [
    { name: "Moe Style", promptAddition: ", Moe Style", imagePath: "https://image.aianime-generator.org/cute.png" },
    { name: "Oil Paint", promptAddition: ", oil painting style", imagePath: "https://image.aianime-generator.org/oilstyle.jpeg" },
    { name: "Sketch", promptAddition: ", pencil sketch", imagePath: "https://image.aianime-generator.org/sketch.jpeg" },
    { name: "Watercolor", promptAddition: ", watercolor painting", imagePath: "https://image.aianime-generator.org/watercolor.jpeg" },
    { name: "Realistic", promptAddition: ", realistic style", imagePath: "https://image.aianime-generator.org/realistic.webp" },
  ];

  const examples = [
    { image: 'https://image.aianime-generator.org/Moe_style.webp', prompt: "Moe style, a girl, wearing swimwear" },
    { image: 'https://image.aianime-generator.org/highschool.webp', prompt: "High school girl under cherry blossom tree, wearing uniform, long hair flowing, sunlight filtering through leaves, soft color palette" },
    { image: 'https://image.aianime-generator.org/magicgirl.webp', prompt: "Anime girl, isekai protagonist, long silver hair, magical staff, ornate fantasy outfit, detailed eyes, ethereal glow, floating islands background, cel shading" },
    { image: 'https://image.aianime-generator.org/pinkmagic.webp', prompt: "Magical girl transformation, flowing ribbons, sparkles, pastel color scheme, expressive eyes, elaborate costume design, starry background, shoujo sparkles" },
    { image: 'https://image.aianime-generator.org/cute.png', prompt: "Vtuber-style character, kawaii design, animal ears and tail, oversized hoodie, heart-shaped pupils, cute facial expression, pastel color palette, bubbly background" },
  ];

  useEffect(() => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('userId', userId);
    }
    document.cookie = `user_id=${userId}; path=/; max-age=31536000; SameSite=Strict`;

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const generateContent = async () => {
    if (loading) return;

    setLoading(true);
    setShowAd(true);
    setShowExamples(false);

    const input = {
      prompt: prompt,
      steps: 50,
      guidance: 3.5,
      aspect_ratio: aspectRatio,
    };

    const newImage = {
      id: Date.now(),
      prompt: prompt,
      aspectRatio: aspectRatio,
      timestamp: new Date().toLocaleString(),
      loading: true
    };

    setGeneratedImages(prevImages => [newImage, ...prevImages]);

    try {
      const response = await fetch('/api/generate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input }),
      });

      if (response.ok) {
        const { image_url } = await response.json();
        setGeneratedImages(prevImages =>
          prevImages.map(img =>
            img.id === newImage.id
              ? { ...img, image: image_url, loading: false }
              : img
          )
        );
      } else {
        throw new Error('Failed to generate content');
      }
    } catch (error) {
      console.error('Error generating content:', error);
      setGeneratedImages(prevImages =>
        prevImages.map(img =>
          img.id === newImage.id
            ? { ...img, loading: false, error: error.message }
            : img
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'generated-image.png';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('An error occurred while downloading the image.'));
  };

  const handleRegenerate = (prompt, aspectRatio) => {
    setPrompt(prompt);
    setAspectRatio(aspectRatio);
    // 不再直接调用generateContent，而是让用户编辑参数
  };

  const handleStylePresetClick = (promptAddition) => {
    setPrompt(prev => prev + promptAddition);
  };

  const handleExampleClick = (example) => {
    setPrompt(example.prompt);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: '#1f1f1f',
      color: '#fff',
      fontFamily: 'Arial, sans-serif',
    }}>
      <header style={{
        backgroundColor: '#111827',
        padding: '1rem',
      }}>
        <div style={{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Link to="/" style={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '1.25rem',
            textDecoration: 'none',
          }}>
            Flux AI Anime Generator
          </Link>
        </div>
      </header>

      <div style={{ display: 'flex', flex: 1 }}>
        <div style={{
          width: '30%',
          padding: '20px',
          borderRight: '1px solid #333',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'auto'
        }}>
          <div style={{ marginBottom: '20px' }}>
            <h4 style={{ margin: '0 0 10px 0', fontSize: '14px', fontWeight: '600' }}>Prompt</h4>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Please enter a prompt"
              style={{
                width: '100%',
                height: '120px', // 增加高度
                padding: '10px',
                fontSize: '16px',
                color: '#fff',
                backgroundColor: '#333',
                borderRadius: '5px',
                border: 'none',
                resize: 'none',
              }}
            />
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h4 style={{ margin: '0 0 10px 0', fontSize: '14px', fontWeight: '600' }}>Ratio</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              {ratios.map((ratio) => (
                <RatioButton
                  key={ratio}
                  ratio={ratio}
                  selected={aspectRatio === ratio}
                  onClick={setAspectRatio}
                />
              ))}
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <h4 style={{ margin: '0 0 10px 0', fontSize: '14px', fontWeight: '600' }}>Style Presets</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {stylePresets.map((preset, index) => (
                <StylePreset
                  key={index}
                  name={preset.name}
                  promptAddition={preset.promptAddition}
                  imagePath={preset.imagePath}
                  onClick={handleStylePresetClick}
                />
              ))}
            </div>
          </div>
          <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '30%',
            padding: '20px',
            backgroundColor: '#1f1f1f',
            borderTop: '1px solid #333',
          }}>
            <button
              onClick={generateContent}
              disabled={loading}
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '16px',
                color: '#fff',
                backgroundColor: loading ? '#4b5563' : '#7e22ce',
                border: 'none',
                borderRadius: '5px',
                cursor: loading ? 'not-allowed' : 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              {loading ? 'Generating...' : 'Generate'}
            </button>
          </div>
        </div>

        <div style={{ width: '70%', padding: '20px', overflowY: 'auto' }}>
          {showExamples ? (
            <div>
              <h3 style={{ marginBottom: '20px' }}>Examples</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {examples.map((example, index) => (
                  <ExampleCard
                    key={index}
                    image={example.image}
                    prompt={example.prompt}
                    onClick={() => handleExampleClick(example)}
                  />
                ))}
              </div>
            </div>
          ) : (
            generatedImages.map((image) => (
              <ImageCard
                key={image.id}
                image={image.image}
                prompt={image.prompt}
                aspectRatio={image.aspectRatio}
                onRegenerate={() => handleRegenerate(image.prompt, image.aspectRatio)}
                onDownload={() => handleDownload(image.image)}
                timestamp={image.timestamp}
                loading={image.loading}
                error={image.error}
              />
            ))
          )}
        </div>
      </div>

      {showAd && <AdPopup onClose={() => setShowAd(false)} />}
    </div>
  );
};

export default ReplicateFluxServer;