// src/pages/TermsOfService.js
import React from 'react';
import Footer from '../components/LandingPage/Footer'; // 确保路径正确

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-purple-900 text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
      <div className="bg-gray-800 rounded-lg shadow-lg p-8">
        <p className="text-sm text-gray-400 mb-6">Last updated: July 23, 2024</p>
        
        {/* 页面内容 */}
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing and using our website, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using this website's particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.
        </p>        
        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Description of Service</h2>
        <p className="mb-4">
          Our website provides users with access to tools and resources for generating AI manga and anime content. This includes a variety of third-party AI tools which we recommend based on user needs and preferences.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">3. User Obligations</h2>
        <p className="mb-4">
          As a user of our website, you agree to use the site responsibly and legally. You must not use the site to engage in any illegal or harmful activities, including but not limited to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li className="mb-2">Uploading or sharing content that is unlawful, defamatory, or infringing on intellectual property rights.</li>
          <li className="mb-2">Attempting to gain unauthorized access to our website or servers.</li>
          <li className="mb-2">Engaging in any activity that could disrupt or interfere with the functionality of the website.</li>
        </ul>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Privacy and Data Handling</h2>
        <p className="mb-4">
          We value your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your data.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">5. User Account and Data Management</h2>
        <p className="mb-4">
          Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. You may update or delete your personal data and account at any time by accessing your account settings or contacting us directly.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">6. Intellectual Property</h2>
        <p className="mb-4">
          All content on this website, including text, graphics, logos, and images, is the property of our website or its content suppliers and is protected by international copyright laws. Users may not reproduce, distribute, or create derivative works from any content without explicit permission.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">7. Termination</h2>
        <p className="mb-4">
          We reserve the right to terminate or suspend access to our website and services, without prior notice or liability, for any reason, including if you breach the Terms of Service.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">8. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify or replace these Terms of Service at any time. Your continued use of the website following any changes signifies your acceptance of the new terms.
        </p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms of Service, please contact us at office@aianime-generator.org.
        </p>
      </div>
      {/* 在TermsOfService页面不显示Footer */}
      {false && <Footer />}
    </div>
  );
};

export default TermsOfService;